@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');

body {
    margin: 0;
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */   
    overflow-x: hidden;
}
:not(.show-scrollbar)::-webkit-scrollbar {
  display: none;
}
.show-scrollbar::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}
.show-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.show-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4b5058;
  border-radius: 10px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, ‘Courier New’,
      monospace;
}